<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <h5>Usuário</h5>

        <form @submit.prevent="salvar">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="nome">Nome</label>
              <InputText
                id="nome"
                v-model="usuario.nome"
                type="text"
                readonly />
            </div>
            <div class="field col-12 md:col-6">
              <label for="username">Username</label>
              <InputText
                id="username"
                v-model="usuario.username"
                type="text"
                readonly />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="email">E-mail</label>
              <InputText
                id="email"
                v-model="usuario.email"
                type="text"
                readonly />
            </div>
            <div class="field col-12 md:col-6">
              <label for="telefone">Telefone</label>
              <InputText
                id="telefone"
                v-model="usuario.telefone"
                type="text"
                readonly />
            </div>
          </div>
          <!--
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                  <Checkbox id="ativo" v-model="usuario.ativo" :binary="true" /> ativo
              </div>
            </div>
            -->
          <div class="p-fluid formgrid grid">
            <slot name="campos"></slot>
          </div>
          <Button type="submit" label="Salvar" class="md:col-2"></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Usuario from '@/domain/Usuario.js'
import UsuarioService from '@/service/UsuarioService.js'

export default {
  name: 'UsuarioForm',

  props: {
    metodoSalvarFilho: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      usuario: new Usuario(),
      id: this.$route.params.id,
    }
  },

  created() {
    this.usuarioService = new UsuarioService(this.$http)

    if (this.id) {
      this.usuarioService.getUsuario(this.id).then((res) => {
        this.usuario = res
      })
    }
  },

  methods: {
    salvar() {
      this.usuarioService.saveUsuario(this.usuario).then(
        (res) => {
          this.usuario = res.data
          if (this.metodoSalvarFilho) {
            this.metodoSalvarFilho(this.usuario)
          }
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        },
      )
    },
  },
}
</script>

<style></style>
