<template>
  <div>
    <usuario-form :metodoSalvarFilho="salvarUsuarioConsignataria">
      <!--
    <template slot="campos"> 
      <div class="field col-12 md:col-6">
          <Checkbox id="usuarioMaster" v-model="usuarioConsignataria.usuarioMaster" :binary="true" /> usuário master
      </div>
    </template>
    -->
    </usuario-form>
  </div>
</template>

<script>
import UsuarioForm from '@/components/shared/usuario/usuarioForm.vue'
import UsuarioConsignataria from '@/domain/UsuarioConsignataria.js'
import UsuarioService from '@/service/UsuarioService.js'

export default {
  components: {
    'usuario-form': UsuarioForm,
  },
  data() {
    return {
      usuarioConsignataria: new UsuarioConsignataria(),
      consignataria: {
        id: this.$route.params.idConsignataria,
      },
    }
  },

  created() {
    this.usuarioService = new UsuarioService(this.$http)
  },

  methods: {
    salvarUsuarioConsignataria(usuario) {
      this.usuarioConsignataria.consignataria = this.consignataria
      this.usuarioConsignataria.id = usuario.id
      this.usuarioConsignataria.usuario = usuario
      this.usuarioService
        .saveUsuarioConsignataria(this.usuarioConsignataria)
        .then(() => {
          this.$router.push({ name: 'consignataria-usuarios' })
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },
  },
}
</script>

<style></style>
