export default class UsuarioConsignataria {
  constructor(
    id = null,
    usuario = null,
    usuarioMaster = null,
    consignataria = null,
    ativo = true,
  ) {
    this.id = id
    this.usuario = usuario
    this.usuarioMaster = usuarioMaster
    this.consignataria = consignataria
    this.ativo = ativo
  }
}
